


import {Component, Vue, Watch, Ref} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import {TNote} from '@/_types/store/note.type';
import {Validations} from 'vuelidate-property-decorators';
import {TVuelidateRuleSet} from '@/_types/vuelitation-rule-set.type';
import {maxLength, required} from 'vuelidate/lib/validators';
import {TCreateOrEditNoteParams, TGetNotesParams, TNoteDataFields} from '@/_api/notes/notes.api';
import {TUser} from '@/_types/user.type';
import {TApiListResponse} from '@/_types/api/api-list-response.type';
import {TContact} from '@/_types/contact.type';
import {TOpenEwSharerPayload} from '@/_store/ew-sharer.store';
import {TTab} from '@/_ui/tabs/types/tabs.type';
import SearchBar from '@/_components/search-bar/search-bar.vue';
import SegmentControl from '@/_ui/segment-control/segment-control.vue';
import iconShare from '@/_modules/icons/components/icon-share.vue';
import iconStar from '@/_modules/icons/components/icon-star.vue';
import IconEwStar from '@/_modules/icons/components/icon-ew-star.vue';
import switchCheckbox from '@/views/components/parts/switch-checkbox.vue';
import EwAvatar from '@/_modules/standalone-company/components/UI/ew-avatar/ew-avatar.vue';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import PageTitle from '@/_components/page-title/page-title.vue';
import ModuleHasNoItems from '@/_components/module-has-no-items/module-has-no-items.vue';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import {TEvent} from '@/_types/event.type';
import IconNotes from '@/_modules/icons/components/sidebar/icon-notes.vue';

const NOTES_PER_PAGE = 20;

@Component({
  components: {
    SearchBar,
    PageTitle,
    SegmentControl,
    IconEwStar,
    iconShare,
    iconStar,
    switchCheckbox,
    EwAvatar,
    ModuleHasNoItems,
    EwButton,
    IconNotes,
  },
})
export default class Notes extends Vue {

  @Ref('cardCreate') cardCreate: HTMLElement;

  @Getter('_eventStore/event') event: TEvent;
  @Getter('_userStore/user') user: TUser;
  @Getter('promoPageStore/contact') myself: TContact;
  @Getter('notesStore/notesStoreStateEntity') notesStoreEntity: TApiListResponse<TNote>;
  @Getter('notesStore/isNotesStoreStateEntityLoading') isLoading: boolean;

  @Action('notesStore/getAllNotes') getAllNotes: (payload: TGetNotesParams) => TApiListResponse<TNote>;
  @Action('notesStore/getMyNotes') getMyNotes: (payload: TGetNotesParams) => TApiListResponse<TNote>;
  @Action('notesStore/addFav') addFav: (noteId: number) => Promise<void>;
  @Action('notesStore/removeFav') removeFav: (noteId: number) => Promise<void>;
  @Action('notesStore/removeNote') deleteNote: (noteId: number) => Promise<void>;
  @Action('notesStore/editNote') editNote: (payload: TCreateOrEditNoteParams) => Promise<boolean>;
  @Action('notesStore/addNote') addNote: (payload: TCreateOrEditNoteParams) => Promise<TNote>;
  @Action('contactsStore/openContactCard') openContactCard: (params: { contactId: number; startupTabName?: string}) => void;
  @Action('ewSharerStore/openSharer') openSharer: (payload: TOpenEwSharerPayload) => void;
  @Action('ewSharerStore/closeSharer') closeSharer: () => void;

  public sharerPreviousId: number;

  public noteListSegmentTabs: TTab[] = [
    {
      title: this.$t('navigation.all'),
      isActive: true,
      index: 0,
      alias: 'allNotesTab',
    },
    {
      title: this.$t('navigation.my'),
      isActive: false,
      index: 1,
      alias: 'myNotesTab',
    },
  ];

  @Validations()
  public readonly validations: TVuelidateRuleSet<TNote> = {
    editedNoteData: {
      title: {
        required,
      },
      text: {
        required,
        maxLength: maxLength(2000)
      },
    },
    createFormData: {
      title: {
        required,
      },
      text: {
        required,
        maxLength: maxLength(2000)
      },
    },
  }
  public isCreateNewNoteFormVisible: boolean = false;
  public isEditingANote: boolean = false;
  public workingWithNoteId: number = null;
  public listPageSize: number = NOTES_PER_PAGE;
  public listType: 'all' | 'own' = 'all';
  public editedNoteData: TNoteDataFields = {
    title: '',
    text: '',
    is_private: true,
  };
  public createNoteFormData: TNoteDataFields = {
    title: '',
    text: '',
    is_private: true,
  };
  public isAddingNewNoteInProgress: boolean = false;
  public isEditingOrDeletingNoteInProgress: boolean = false;

  public get getEventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get userId(): number {
    return (this.user && this.user.id) || null;
  }

  public get isNotesListEmpty(): boolean {
    return (this.notesStoreEntity && this.notesStoreEntity.List && this.notesStoreEntity.List.length === 0);
  }

  public get isNoItemsActionCreateButtonVisible(): boolean {
    return !!(this.event && this.myself);
  }

  public get noItemsText(): string {
    return (this.listType === 'own' ? this.$t('noMyAnnouncements') : this.$t('noPublicAnnouncements')) as string;
  }

  @Watch('isEditingANote')
  public onIsEditingANoteChange(newVal: boolean): void {
    if (newVal) {
      this.setEditedNoteData();
    }
  }

  @Watch('userId', {immediate: true})
  public onUserIdChange(newVal: number): void {
    if (newVal) {
      this.callNoteList();
    }
  }

  public setNoteIsPrivate($event: boolean): void {
    this.createNoteFormData.is_private = !$event;
  }

  public async callNoteList(): Promise<void> {
    const payload: TGetNotesParams = {
      eventId: this.getEventId,
      queryParams: {
        offset: 0,
        limit: this.listPageSize,
      }
    };
    if (this.listType === 'own') {
      await this.getMyNotes(payload);
    } else if (this.listType === 'all') {
      await this.getAllNotes(payload);
    }

    this.isCreateNewNoteFormVisible = false;
    this.isEditingANote = false;
    this.workingWithNoteId = null;
  }

  public onEditNoteClick(noteId: number): void {
    this.isEditingANote = true;
    this.workingWithNoteId = noteId;
  }

  public async onAddToFavClick(id: number): Promise<void> {
    await this.addFav(id);
    await this.callNoteList();
  }

  public async onRemoveFromFavClick(id: number): Promise<void> {
    await this.removeFav(id);
    await this.callNoteList();
  }

  public async onDeleteNoteClick(id: number): Promise<void> {
    this.workingWithNoteId = id;
    this.isEditingOrDeletingNoteInProgress = true;
    await this.deleteNote(id);
    await this.callNoteList();
    this.isEditingOrDeletingNoteInProgress = false;
  }

  public transformDate(dateString: string): string {
    return this.$moment(this.$moment(dateString)).format('DD MMM YYYY');
  }

  public transformTime(dateString: string): string {
    return this.$moment(this.$moment(dateString)).format('HH:mm');
  }

  public onNoteListSegmentActiveTabUpdate(activeTab: TTab, activeTabIndex: number): void {
    this.noteListSegmentTabs.forEach((tab, idx) => {
      tab.isActive = idx === activeTabIndex;
    });

    this.dispatchSegmentTabClickHandlers();
  }

  public dispatchSegmentTabClickHandlers(): void {
    this.listType = this.isCurrentListTypeMy ? 'own' : 'all';
    this.callNoteList();
  }

  public get isCurrentListTypeMy(): boolean {
    return !!this.noteListSegmentTabs.find(tab => tab.alias === 'myNotesTab' && tab.isActive);
  }

  public getTruncatedText(text: string, length: number): string {
    if (!text || !length || length <= 0 || text.length <= length) {
      return text;
    }
    return `${text.substring(0, length)}...`;
  }

  public linkifyUrls(inputString: string): string {
    return ChatHelper.createLinks(inputString);
  }

  public async onSubmitEditedNote(): Promise<void> {
    this.$v.editedNoteData.$touch();
    if (this.$v.editedNoteData.$pending || this.$v.editedNoteData.$invalid) {
      return;
    }

    this.isEditingOrDeletingNoteInProgress = true;
    await this.editNote({queryParams: this.editedNoteData, noteId: this.workingWithNoteId});
    await this.callNoteList();
    this.isEditingANote = false;
    this.isEditingOrDeletingNoteInProgress = false;
  }

  public setEditedNoteData(): void {
    const note = this.notesStoreEntity.List.find(note => note.id === this.workingWithNoteId);
    if (note) {
      this.editedNoteData.title = note.title;
      this.editedNoteData.text = note.text;
      this.editedNoteData.is_private = note.is_private;
    }
  }

  public async createNote(): Promise<void> {
    this.$v.createFormData.$touch();

    if (this.$v.createFormData.$pending || this.$v.createFormData.$invalid) {
      return;
    }

    this.isAddingNewNoteInProgress = true;
    await this.addNote({eventId: this.getEventId, queryParams: this.createNoteFormData});
    await this.callNoteList();
    this.isAddingNewNoteInProgress = false;

    this.createNoteFormData.title = '';
    this.createNoteFormData.text = '';
    this.createNoteFormData.is_private = false;
    this.isCreateNewNoteFormVisible = false;
  }

  public onShareButtonMouseover(id: number): void {
    this.setShareNoteId(id);
  }

  public setShareNoteId(id: number): void {
    this.workingWithNoteId = id;
  }

  public onShareButtonMouseleave(): void {
    if (!this.isEditingANote) {
      this.setShareNoteId(null);
    }
  }

  public share(event: KeyboardEvent, id: number): void {
    if (this.sharerPreviousId === id) {
      this.closeSharer();
    } else {
      this.openSharer({
        eventTarget: event.target as Element,
      });
    }
    this.sharerPreviousId = id;
  }

  // TODO: instead of this crap, make real pagination — request a page, push the result. Show progress indication, display «the end» message
  public loadMore(): void {
    if (this.listPageSize <= this.notesStoreEntity.Total) {
      this.listPageSize += NOTES_PER_PAGE;
      this.callNoteList();
    }
  }

  public onContactClick(contactId: number): void {
    if (this.myself.id === contactId) {
      return;
    }

    this.openContactCard({
      contactId,
    });
  }

  public onCreateNewClick(): void {
    this.isCreateNewNoteFormVisible = true;
    window.setTimeout(this.highlightCreateCard, 100);
  }

  public highlightCreateCard(): void {
    this.cardCreate.classList.add('card-highlighted');
  }

  public hideCreateForm(): void {
    this.isCreateNewNoteFormVisible = false;
  }

  public onCancelCreate(): void {
    this.cardCreate.classList.remove('card-highlighted');
    window.setTimeout(this.hideCreateForm, 310);
  }
}
